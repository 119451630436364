import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import deliveryMan from "../../assets/ImageHomeWorker.png"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import EmailService from "../../services/EmailService"

export default function CollaboratorScreen() {
    useEffect(() => {
        document.title = "Logplace - Nossos Serviços"
    }, [])

    const [headerFixed, setHeaderFixed] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [formData, setFormData] = useState<{
        name: string
        email: string
        phone: string
        linkedin: string
        info: string
        resume: File | null
    }>({
        name: "",
        email: "",
        phone: "",
        linkedin: "",
        info: "",
        resume: null
    })
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        linkedin: "",
        resume: ""
    })

    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/rastreamento")
    }

    const headerFixedTrue = () => {
        setHeaderFixed(true)
    }

    const headerFixedFalse = () => {
        setHeaderFixed(false)
    }

    const validateForm = () => {
        const newErrors = {
            name: "",
            email: "",
            phone: "",
            linkedin: "",
            resume: ""
        }
        let isValid = true

        if (!formData.name) {
            toast.error("Nome é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!formData.email || !emailRegex.test(formData.email)) {
            toast.error("Email inválido", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        const phoneRegex = /^\d{2}\d{8,9}$/
        if (!formData.phone || !phoneRegex.test(formData.phone)) {
            toast.error("Telefone inválido (ex: 11999999999)", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        if (!formData.linkedin) {
            toast.error("Perfil do LinkedIn é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        if (!formData.resume) {
            newErrors.resume = "Currículo é obrigatório"
            toast.error("Currículo é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        setErrors(newErrors)
        return isValid
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (validateForm()) {
            const formDataToSend = new FormData()
            formDataToSend.append("name", formData.name)
            formDataToSend.append("email", formData.email)
            formDataToSend.append("phone", formData.phone)
            formDataToSend.append("linkedin", formData.linkedin)
            formDataToSend.append("info", formData.info)
            formDataToSend.append("resume", formData.resume as File)

            try {
                const response = await EmailService.sendEmail(
                    "Quero ser colaborador",
                    "Nova candidatura de emprego " +
                        formData.name +
                        ":" +
                        formData.email +
                        ":" +
                        formData.phone +
                        ":" +
                        formData.linkedin +
                        ":" +
                        formData.info,
                    "colaborador",
                    formData.resume as File
                )

                if (response.status === 200) {
                    toast.success("Dados enviados com sucesso", {
                        style: {
                            backgroundColor: "#4CAF50",
                            color: "#fff",
                            padding: "16px",
                            borderRadius: "10px",
                            border: "2px solid #4CAF50",
                            fontSize: "16px"
                        }
                    })
                } else {
                    toast.error("Erro ao enviar os dados", {
                        style: {
                            backgroundColor: "#f44336",
                            color: "#fff",
                            padding: "16px",
                            borderRadius: "10px",
                            border: "2px solid #D32F2F",
                            fontSize: "16px"
                        }
                    })
                }
            } catch (error) {
                toast.error("Erro ao enviar os dados", {
                    style: {
                        backgroundColor: "#f44336",
                        color: "#fff",
                        padding: "16px",
                        borderRadius: "10px",
                        border: "2px solid #D32F2F",
                        fontSize: "16px"
                    }
                })
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Trabalhe Conosco</title>
                <title>Trabalhe Conosco</title>
                <meta
                    name="description"
                    content="Oferecemos soluções logísticas sob medida para sua operação. Fulfillment, B2B, B2C com inteligência e tecnologia."
                />
                <meta
                    name="keywords"
                    content="logística, fulfillment, B2C, B2B, transporte, soluções logísticas, entregas, e-commerce"
                />
                <meta name="author" content="LogPlace" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta property="og:title" content="Nossos Serviços" />
                <meta
                    property="og:description"
                    content="Somos líderes em soluções logísticas para o pequeno, médio e-commerce e grandes operações de varejo."
                />
                <meta
                    property="og:image"
                    content="https://logplace.com.br/wp-content/uploads/2021/10/map.png"
                />
                <meta property="og:url" content="https://logplace.com.br" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div
                className="flex flex-col flex-1 w-full h-screen bg-white relative xl:overflow-y-auto"
                onScroll={(event: any) => {
                    if (event.currentTarget.scrollTop > 10) {
                        setHeaderFixed(true)
                    } else {
                        setHeaderFixed(false)
                    }

                    if (event.currentTarget.scrollTop > 500) {
                        setIsVisible(true)
                    } else {
                        setIsVisible(false)
                    }
                }}
            >
                <div className="absolute inset-0 w-full bg-gradient-to-b from-white from-5% via-secondary-1 via-30% via-secondary-2 via-35% to-primary to-80% z-0">
                    <ToastContainer
                        position="bottom-right"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        icon={false}
                        limit={1}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Header
                        background={headerFixed ? " " : ""}
                        headerFixedTrue={headerFixedTrue}
                        headerFixedFalse={headerFixedFalse}
                    />
                    <div className="h-[10rem]" />
                    <div className="h-[13rem] justify-center items-center flex flex-col 2xl:px-[32rem] ">
                        <h1 className="text-6xl text-center text-white font-bold">
                            Trabalhe Conosco
                        </h1>
                    </div>
                    <div className="h-[45rem] xl:h-[48rem] flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:space-x-5 lg:px-44">
                        <div className="flex flex-row w-full h-full p-4 justify-center items-center">
                            <div className="w-1/2 h-full hidden 2xl:flex">
                                <img
                                    className="h-[100%] w-[100%] object-contain"
                                    src={deliveryMan}
                                    alt="entregador"
                                />
                            </div>
                            <div className="w-full lg:w-2/3 2xl:w-1/2 h-full flex flex-col rounded-lg bg-secondary-1 p-4">
                                <div className="flex-1 flex justify-center items-center">
                                    <h1 className="text-white text-2xl font-bold">
                                        Faça parte do nosso sucesso!
                                    </h1>
                                </div>
                                <form
                                    className="flex-5 space-y-4 justify-center flex flex-col"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="flex flex-row h-12 ">
                                        <input
                                            className="w-full rounded-md px-4 py-2"
                                            placeholder="Nome Completo"
                                            value={formData.name}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    name: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-row h-12 space-x-4">
                                        <input
                                            className="w-1/2 rounded-md px-4 py-2"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    email: e.target.value
                                                })
                                            }
                                        />

                                        <input
                                            className="w-1/2 rounded-md px-4 py-2"
                                            placeholder="DDD+Numero (Whatsapp)"
                                            value={formData.phone}
                                            type="tel"
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    phone: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-row h-12 ">
                                        <input
                                            className="w-full rounded-md px-4 py-2"
                                            placeholder="Perfil do Linkedin (link)"
                                            value={formData.linkedin}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    linkedin: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-row h-24">
                                        <textarea
                                            className="w-full p-2 rounded-md resize-none"
                                            placeholder="Outras informações"
                                            value={formData.info}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    info: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-row h-12">
                                        <input
                                            className="w-full h-full text-white file:rounded-lg file:border-none file:px-4 file:py-2 file:font-semibold file:bg-white"
                                            type="file"
                                            placeholder="Anexe seu currículo"
                                            accept=".pdf,.doc,.docx"
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    resume: e.target.files
                                                        ? e.target.files[0]
                                                        : null
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="w-full h-12">
                                        <button
                                            className="bg-green-400 rounded-lg h-full w-full"
                                            type="submit"
                                        >
                                            <h1 className="text-white font-semibold">
                                                QUERO TRABALHAR COM A LOGPLACE
                                            </h1>
                                        </button>
                                    </div>
                                    <div className="w-full space-x-2 h-12">
                                        <p className="text-white text-center">
                                            Conforme estabelecido na Lei LGPD
                                            3218/2019, informamos que ao enviar
                                            os seus dados pessoais você autoriza
                                            a utilização dos dados fornecidos
                                            para utilização em processos
                                            internos.
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}
