import { useNavigate } from "react-router-dom"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import Rastreamento_2_0 from "../../assets/Banner Rastreamento LogPlace (1024 x 1366 px).png"
import Rastreamento_3_0 from "../../assets/Banner Rastreamento LogPlace (390 x 844 px).png"
import Rastreamento_1_0 from "../../assets/Banner Rastreamento LogPlace.png"
import Agregado_2_0 from "../../assets/SEJA UM AGREGADO (1024 x 1366 px).png"
import Agregado_1_0 from "../../assets/SEJA UM AGREGADO (1920x850).png"
import Agregado_3_0 from "../../assets/SEJA UM AGREGADO (390 x 844 px).png"
import Servicos_2_0 from "../../assets/Serviços Logísticos (1024 x 1366 px).png"
import Servicos_1_0 from "../../assets/Serviços Logísticos (1920 x 850 px).png"
import Servicos_3_0 from "../../assets/Serviços Logísticos (390 x 844 px).png"
import "./Caroussel.css"

const SliderTest = () => {
    const navigate = useNavigate()

    const handleClick = (value: String) => {
        navigate("/" + value)
    }

    return (
        <div className="swiper-container">
            <Swiper
                modules={[Navigation, Autoplay, Pagination]}
                navigation={{
                    nextEl: ".custom-next",
                    prevEl: ".custom-prev"
                }}
                autoplay={{ delay: 5000 }}
                loop={true}
                speed={500}
                className="custom-swiper h-[55rem] md:h-[65rem] xl:h-[42rem]"
            >
                {/*<SwiperSlide>
                    {/*<div className="flex-3 h-full justify-center items-center flex">
                        <div className="items-start flex flex-col xl:ml-56">
                            <p className="font-bold text-white text-xl md:text-3xl">
                                Quer saber onde está seu pedido ?
                            </p>
                            <p className="font-normal text-white text-md md:text-lg">
                                Saiba onde ele está em poucos cliques
                            </p>
                            <button
                                onClick={handleClick}
                                className="bg-secondary-2 mt-4 shadow-sm text-white rounded-full w-56 h-14"
                            >
                                <p className="font-bold">Rastreio</p>
                            </button>
                        </div>
                    </div>
                    <div className="w-full h-full bg-primary">
                        <img
                            className="object-contain w-full h-full"
                            src={Agregado_1_0}
                            alt="Trabalhador4"
                        />
                    </div>
                </SwiperSlide>*/}
                <SwiperSlide>
                    <a
                        className="w-full h-full bg-primary cursor-pointer shadow-lg shadow-black"
                        onClick={() => handleClick("trabalheconosco/agregado")}
                    >
                        <img
                            className="xl:flex hidden object-contain w-full h-full 2xl:object-cover"
                            src={Agregado_1_0}
                            alt="Trabalhador"
                        />
                        <img
                            className="sm:flex sm:object-cover md:object-contain xl:hidden hidden object-contain w-full h-full"
                            src={Agregado_2_0}
                            alt="Trabalhador2"
                        />
                        <img
                            className="sm:hidden object-cover w-full h-full"
                            src={Agregado_3_0}
                            alt="Trabalhador3"
                        />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className="w-full h-full bg-primary cursor-pointer shadow-lg shadow-black"
                        onClick={() => handleClick("servicos")}
                    >
                        <img
                            className="xl:flex hidden object-contain w-full h-full 2xl:object-cover"
                            src={Servicos_1_0}
                            alt="Trabalhador"
                        />
                        <img
                            className="sm:flex sm:object-cover md:object-contain xl:hidden hidden object-contain w-full h-full"
                            src={Servicos_2_0}
                            alt="Trabalhador2"
                        />
                        <img
                            className="sm:hidden object-cover w-full h-full"
                            src={Servicos_3_0}
                            alt="Trabalhador3"
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className="w-full h-full bg-primary cursor-pointer shadow-lg shadow-black"
                        onClick={() => handleClick("rastreamento")}
                    >
                        <img
                            className="xl:flex hidden object-contain w-full h-full 2xl:object-cover"
                            src={Rastreamento_1_0}
                            alt="Trabalhador"
                        />
                        <img
                            className="sm:flex sm:object-cover md:object-contain xl:hidden hidden object-contain w-full h-full"
                            src={Rastreamento_2_0}
                            alt="Trabalhador2"
                        />
                        <img
                            className="sm:hidden object-cover w-full h-full"
                            src={Rastreamento_3_0}
                            alt="Trabalhador3"
                        />
                    </div>
                </SwiperSlide>
            </Swiper>

            {/*<button className="bg-white w-14 h-14 custom-prev absolute top-[45%] left-4 rounded-full cursor-pointer z-10 justify-center items-center flex">
                <FaChevronLeft size={25} className="" />
            </button>
            <button className="bg-white w-14 h-14 custom-next absolute top-[45%] right-4 rounded-full cursor-pointer z-10 justify-center items-center flex">
                <FaChevronRight size={25} className="" />
            </button>*/}
        </div>
    )
}

export default SliderTest
