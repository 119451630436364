import { useEffect, useState } from "react"
import { CiMenuBurger } from "react-icons/ci"
import { useNavigate } from "react-router-dom"
import logoHeader from "../../assets/logoHeader.png"

interface HeaderProps {
    background?: string
    headerFixedTrue?: () => void
    headerFixedFalse?: () => void
}

export default function Header({
    background,
    headerFixedTrue,
    headerFixedFalse
}: HeaderProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const [backgroundHeader, setBackgroundHeader] =
        useState<boolean>(!!background)

    const [showDetailsMenu, setShowDetailsMenu] = useState(false)

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const navigate = useNavigate()

    const handleToNavigate = (value: String) => {
        navigate("/" + value)
    }

    useEffect(() => {
        setBackgroundHeader(!!background)
    }, [background])

    return (
        <>
            <div
                onMouseLeave={() => {
                    setShowDetailsMenu(false)
                }}
                className={`w-full z-40 transition-all duration-500 fixed ${
                    showDetailsMenu || backgroundHeader
                        ? "bg-white shadow-md"
                        : " bg-transparent"
                } ${showDetailsMenu ? "h-[20rem]" : "h-[9rem]"}`}
            >
                <div
                    className="z-10 flex-wrap xl:flex-nowrap flex bg-transparent h-auto relative  mt-4 justify-between 2xl:justify-around
                                items-center"
                >
                    <button
                        onClick={() => {
                            handleToNavigate("home")
                            setShowDetailsMenu(false)
                        }}
                        className="w-72 md:w-[28rem] xl:justify-center items-end flex "
                    >
                        <img
                            src={logoHeader}
                            className="w-[65%] h-[65%] md:w-[70%] md:h-[70%]"
                            alt="Logo"
                        />
                    </button>
                    <button
                        onClick={handleMenuToggle}
                        className="block xl:hidden p-2 md:mr-8 mr-8 text-white"
                    >
                        {isMenuOpen ? (
                            <div className="rotate-90 h-12 w-12 sm:w-14 sm:h-14 md:w-16 md:h-16 bg-white shadow-md justify-center items-center rounded-lg flex">
                                <CiMenuBurger color="#000" size={25} />
                            </div>
                        ) : (
                            <div className="h-12 w-12 sm:w-14 sm:h-14 md:w-16 md:h-16 bg-white shadow-md justify-center items-center rounded-lg flex">
                                <CiMenuBurger color="#000" size={25} />
                            </div>
                        )}
                    </button>
                    <div
                        className={`hidden md:hidden xl:flex xl:justify-center xl:items-center xl:w-2/3 gap-4 p-4`}
                    >
                        <button
                            onClick={() => {
                                handleToNavigate("home")
                                setShowDetailsMenu(false)
                            }}
                            onMouseEnter={() => {
                                setShowDetailsMenu(false)
                            }}
                            className="w-32 mx-6 sm:w-36 md:w-40 shadow-md rounded-full bg-primary h-12 sm:h-14 flex justify-center items-center"
                        >
                            <h1 className="text-white text-xs sm:text-sm font-bold">
                                Home
                            </h1>
                        </button>
                        <button
                            onClick={() => {
                                handleToNavigate("aempresa")
                                setShowDetailsMenu(false)
                            }}
                            onMouseEnter={() => {
                                setShowDetailsMenu(true)
                            }}
                            className="w-32 mx-6 sm:w-36 md:w-40 shadow-md rounded-full bg-primary h-12 sm:h-14 flex justify-center items-center"
                        >
                            <h1 className="text-white text-xs sm:text-sm font-bold">
                                A Empresa
                            </h1>
                        </button>
                        <button
                            onClick={() => {
                                handleToNavigate("servicos")
                                setShowDetailsMenu(false)
                            }}
                            onMouseEnter={() => {
                                setShowDetailsMenu(false)
                            }}
                            className="w-32 mx-6 sm:w-36 md:w-40 shadow-md rounded-full bg-primary h-12 sm:h-14 flex justify-center items-center"
                        >
                            <h1 className="text-white text-xs sm:text-sm font-bold">
                                Nossos Serviços
                            </h1>
                        </button>
                        <button
                            onClick={() => {
                                handleToNavigate("rastreamento"),
                                    setShowDetailsMenu(false)
                            }}
                            onMouseEnter={() => {
                                setShowDetailsMenu(false)
                            }}
                            className="w-32 mx-16 sm:w-36 md:w-56 shadow-md rounded-full bg-secondary-2 h-12 sm:h-14 flex justify-center items-center"
                        >
                            <h1 className="text-white text-xs sm:text-sm font-bold">
                                Rastreamento
                            </h1>
                        </button>
                    </div>

                    <div
                        className={`z-20 w-full p-2 xl:hidden bg-white ${isMenuOpen ? "" : "hidden"}`}
                    >
                        <div className=" w-full h-full p-4 space-y-4 md:space-y-0 md:flex md:flex-row md:justify-between">
                            <button
                                onClick={() => handleToNavigate("home")}
                                className="font-bold text-xl md:items-start md:flex"
                            >
                                Home
                            </button>
                            <div>
                                <button
                                    onClick={() => handleToNavigate("aempresa")}
                                    className="font-bold text-xl"
                                >
                                    A Empresa
                                </button>
                                <div className="ml-4 mt-2 md:mt-0 flex flex-col items-start">
                                    <button
                                        onClick={() =>
                                            handleToNavigate("aempresa")
                                        }
                                    >
                                        Sobre nós
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleToNavigate("aempresa")
                                        }
                                    >
                                        Trabalhe Conosco
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleToNavigate("aempresa")
                                        }
                                    >
                                        Seja um agregado
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleToNavigate("aempresa")
                                        }
                                    >
                                        Missão, Visão e Valores
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={() => handleToNavigate("servicos")}
                                    className="font-bold text-xl"
                                >
                                    Nossos Serviços
                                </button>
                                <div className="ml-4 mt-2 flex flex-col items-start">
                                    <button
                                        onClick={() =>
                                            handleToNavigate("servicos")
                                        }
                                        className=""
                                    >
                                        Somos a LogPlace
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleToNavigate("servicos")
                                        }
                                        className=""
                                    >
                                        Nossos Clientes
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleToNavigate("servicos")
                                        }
                                        className=""
                                    >
                                        Seja nosso Cliente
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={() =>
                                        handleToNavigate("rastreamento")
                                    }
                                    className="font-bold text-xl"
                                >
                                    Rastreamento
                                </button>
                                <div className="ml-4 mt-2 flex flex-col items-start">
                                    <button
                                        onClick={() =>
                                            handleToNavigate("rastreamento")
                                        }
                                        className=""
                                    >
                                        Rastreio de encomenda
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`absolute top-[8rem] left-0 w-full px-60 h-[50%] justify-start items-center flex transition-opacity ${showDetailsMenu ? "z-50 opacity-100 delay-300" : "opacity-0 delay-0 pointer-events-none"}`}
                >
                    <div className="w-1/3 h-1/3 p-4 flex flex-col justify-start items-start">
                        <button
                            className="text-2xl px-2 font-bold"
                            onClick={() => handleToNavigate("aempresa")}
                        >
                            A Empresa
                        </button>
                        <button
                            className="rounded-full p-2 hover:bg-gray-200"
                            onClick={() =>
                                handleToNavigate("trabalheconosco/colaborador")
                            }
                        >
                            Seja nosso colaborador
                        </button>
                        <button
                            className="rounded-full p-2 hover:bg-gray-200"
                            onClick={() =>
                                handleToNavigate("trabalheconosco/agregado")
                            }
                        >
                            Seja nosso agregado
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
