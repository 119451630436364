import axios, { AxiosRequestConfig } from "axios"
const TK: any = process.env.REACT_APP_BASE_URL_TOKEN
const US: any = process.env.REACT_APP_BASE_URL_USER
const PS: any = process.env.REACT_APP_BASE_URL_PASS
const TRK: any = process.env.REACT_APP_BASE_URL_TRACK
const TrackingService = {
    getTrackingToken: async () => {
        const body = {
            login: US,
            senha: PS
        }

        try {
            const response = await axios.post(TK, body)
            console.log("response", response)
            return response.data
        } catch (error) {
            console.error("Erro ao obter o token de rastreamento:", error)
            throw error
        }
    },

    getInformation: async (
        token: string,
        cpfOrCnpj: string,
        invoiceNumber: string
    ) => {
        const configs: AxiosRequestConfig = {
            headers: {
                token: token
            }
        }

        const body = {
            notafiscal: {
                cpfCnpjDestinatario: cpfOrCnpj,
                numero: invoiceNumber
            }
        }
        return axios.post(TRK, body, configs)
    }

    //getTrackingTokenTESTE: async () => {
    //    const body = {
    //        login: US,
    //        senha: PS
    //    }

    //    try {
    //        const response = await axios.post(
    //            "http://localhost:8080/proxy/login",
    //            body
    //        )
    //        return response.data
    //    } catch (error) {
    //        console.error("Erro ao obter o token de rastreamento:", error)
    //        throw error // Propaga o erro para ser tratado mais acima
    //    }
    //},

    //getInformationTESTE: async (
    //    token: string,
    //    cpfOrCnpj: string,
    //    invoiceNumber: string
    //) => {
    //    const configs: AxiosRequestConfig = {
    //        headers: {
    //            token: token
    //        }
    //    }

    //    const body = {
    //        notafiscal: {
    //            numero: invoiceNumber,
    //            cpfCnpjDestinatario: cpfOrCnpj
    //        }
    //    }
    //    console.log("body", body)
    //    return axios.post(
    //        "http://localhost:8080/proxy/rastreamento",
    //        body,
    //        configs
    //    )
    //}
}

export default TrackingService
