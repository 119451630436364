import { useEffect, useRef, useState } from "react"
import { AiOutlineGlobal } from "react-icons/ai"
import { BsPerson, BsSuitcaseLg, BsTruck } from "react-icons/bs"
import { FaCheck, FaRegHandshake } from "react-icons/fa"
import { GrAchievement, GrUserAdd } from "react-icons/gr"

import { Helmet } from "react-helmet"
import { RiMap2Line } from "react-icons/ri"
import deliveryMan from "../../assets/deliveryMan.png"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import CustomCarousel from "../../components/slider/SimpleSlider"
import ScrollToTopButton from "./components/ScrollToTopButton"

export default function HomeScreen() {
    const mainRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.title = "Logplace – Logística Inteligente"
    }, [])

    const [isVisible, setIsVisible] = useState(false)
    const [headerFixed, setHeaderFixed] = useState(false)

    const scrollToTop = () => {
        if (mainRef.current) {
            mainRef.current.scrollTo({ top: 0, behavior: "smooth" })
        }
    }

    const headerFixedTrue = () => {
        setHeaderFixed(true)
    }

    const headerFixedFalse = () => {
        setHeaderFixed(false)
    }

    return (
        <>
            <Helmet>
                <title>Logplace – Logística Inteligente</title>
                <meta
                    name="description"
                    content="Oferecemos soluções logísticas sob medida para sua operação. Fulfillment, B2B, B2C com inteligência e tecnologia."
                />
                <meta
                    name="keywords"
                    content="logística, fulfillment, B2C, B2B, transporte, soluções logísticas, entregas, e-commerce"
                />
                <meta name="author" content="LogPlace" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta
                    property="og:title"
                    content="Logplace – Logística Inteligente"
                />
                <meta
                    property="og:description"
                    content="Somos líderes em soluções logísticas para o pequeno, médio e-commerce e grandes operações de varejo."
                />
                <meta
                    property="og:image"
                    content="https://logplace.com.br/wp-content/uploads/2021/10/map.png"
                />
                <meta property="og:url" content="https://logplace.com.br" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div
                className="flex flex-col w-full h-screen bg-primary relative overflow-auto xl:overflow-y-auto"
                ref={mainRef}
                onScroll={(event: any) => {
                    if (event.currentTarget.scrollTop > 10) {
                        setHeaderFixed(true)
                    } else {
                        setHeaderFixed(false)
                    }

                    if (event.currentTarget.scrollTop > 500) {
                        setIsVisible(true)
                    } else {
                        setIsVisible(false)
                    }
                }}
            >
                <ScrollToTopButton show={isVisible} scrollPage={scrollToTop} />
                <div className="absolute inset-0 w-full bg-gradient-to-b from-white from-15% via-secondary-1 via-30% via-secondary-2 via-35% to-primary to-80% z-0">
                    <Header
                        background={headerFixed ? " " : ""}
                        headerFixedTrue={headerFixedTrue}
                        headerFixedFalse={headerFixedFalse}
                    />
                    <div className="h-[10rem]" />
                    <div className={`flex bg-transparent`}>
                        <CustomCarousel />
                    </div>
                    <div className="h-[75rem] md:h-[90rem] lg:h-[55rem] xl:h-[55rem] flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:space-x-5 lg:px-44">
                        <div className="flex justify-center w-1/2">
                            <p className="text-4xl font-medium text-center">
                                Oferecemos soluções logísticas sob medida para
                                sua operação
                            </p>
                        </div>
                        <div className="flex flex-col lg:flex-row lg:space-x-5 items-center space-y-2 justify-center w-full">
                            <div className="h-72 w-80 lg:h-80 lg:w-96 bg-white shadow-lg rounded-sm text-center flex flex-col justify-between items-center p-4">
                                <div className="flex-1 justify-center flex items-center">
                                    <div className="h-20 w-20 bg-red-200 rounded-full justify-center items-center flex">
                                        <BsTruck size={50} color="#f74747" />
                                    </div>
                                </div>
                                <div className="flex-1 flex justify-center items-end">
                                    <p className="text-xl font-medium">
                                        Fulfillment
                                    </p>
                                </div>
                                <div className="flex-2 justify-center flex items-center">
                                    <p className="text-sm font-normal">
                                        Fulfillment é o processo logístico e que
                                        permite entregar produtos a clientes no
                                        e-commerce. Ele abrange desde o
                                        processamento do pedido até a separação
                                        no estoque, embalagem, entrega e
                                        pós-venda.
                                    </p>
                                </div>
                            </div>
                            <div className="h-72 w-80 lg:h-80 lg:w-96 bg-white shadow-lg rounded-sm text-center flex flex-col justify-between items-center p-4">
                                <div className="flex-1 justify-center flex items-center">
                                    <div className="h-20 w-20 bg-red-200 rounded-full justify-center items-center flex">
                                        <BsPerson size={50} color="#f74747" />
                                    </div>
                                </div>
                                <div className="flex-1 flex justify-center items-end">
                                    <p className="text-xl font-medium">B2C</p>
                                </div>
                                <div className="flex-2 justify-center flex items-center">
                                    <p className="text-sm font-normal">
                                        O foco principal é a entrega de produtos
                                        diretamente aos consumidores, em vez de
                                        outras empresas. Isso inclui itens
                                        comprados online, em lojas físicas ou
                                        por meio de vários outros canais.
                                    </p>
                                </div>
                            </div>
                            <div className="h-72 w-80 lg:h-80 lg:w-96 bg-white shadow-lg rounded-sm text-center flex flex-col justify-between items-center p-4">
                                <div className="flex-1 justify-center flex items-center">
                                    <div className="h-20 w-20 bg-red-200 rounded-full justify-center items-center flex">
                                        <BsSuitcaseLg
                                            size={50}
                                            color="#f74747"
                                        />
                                    </div>
                                </div>
                                <div className="flex-1 flex justify-center items-end">
                                    <p className="text-xl font-medium">B2B</p>
                                </div>
                                <div className="flex-2 justify-center flex items-center">
                                    <p className="text-sm font-normal">
                                        O principal foco é a entrega de
                                        produtos, suprimentos ou mercadorias de
                                        uma empresa para outra. Isso pode
                                        incluir matérias-primas, produtos
                                        acabados, componentes, vários tipos de
                                        insumos, etc.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[50rem] lg:h-[38rem] xl:h-[40rem] flex flex-row lg:space-x-10 bg-white lg:px-48 lg:py-10">
                        <div className="hidden xl:w-1/2 h-full bg-white xl:flex justify-end items-end">
                            <img
                                className="h-[100%] w-[100%] object-contain"
                                src={deliveryMan}
                                alt="entregador"
                            />
                        </div>
                        <div className="xl:w-1/2 h-full flex flex-col items-center justify-center xl:justify-around space-y-2 xl:space-y-4 p-4">
                            <div className="space-y-2 xl:space-y-0">
                                <p className="text-red-500 font-medium">
                                    ____ Sobre Nós
                                </p>
                                <p className="text-4xl font-medium text-primary">
                                    Inteligência e Tecnologia são sinônimos da
                                    LogPlace.
                                </p>
                                <p>
                                    Com quase 20 anos de experiência em entregas
                                    para 100% do território nacional, somos
                                    líderes no atendimento ao pequeno, médio
                                    e-commerce e gigantes do varejo. Também
                                    traçamos operações complexas de indústria e
                                    governo.
                                </p>
                            </div>
                            <div className="flex flex-row  bg-white w-full h-1/6 lg:h-2/6 2xl:h-2/6 rounded-lg p-2 drop-shadow-lg group">
                                <div className="flex-2 xl:flex-1 flex justify-center items-center hover:bg-transparent">
                                    <div className="w-20 h-20 lg:w-16 lg:h-16 bg-red-200 transition duration-500 ease-in-out group-hover:bg-red-600 rounded-full flex justify-center items-center">
                                        <AiOutlineGlobal
                                            size={45}
                                            className="transition duration-500 ease-in-out group-hover:text-white text-red-600"
                                        />
                                    </div>
                                </div>
                                <div className="flex-5 xl:flex-4 flex flex-col justify-center">
                                    <p className="text-xl font-bold text-primary">
                                        Unidades Próprias
                                    </p>
                                    <p>
                                        Nossas unidades próprias espalhadas pelo
                                        Nordeste garantem uma distribuição
                                        fluida e descomplicada.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-row bg-white w-full h-1/6 lg:h-2/6 2xl:h-2/6 rounded-lg p-2 drop-shadow-lg group">
                                <div className="flex-2 xl:flex-1 flex justify-center items-center">
                                    <div className="w-20 h-20 lg:w-16 lg:h-16 bg-red-200 transition duration-500 ease-in-out group-hover:bg-red-600 rounded-full justify-center items-center flex">
                                        <RiMap2Line
                                            size={45}
                                            className="transition duration-500 ease-in-out group-hover:text-white text-red-600"
                                        />
                                    </div>
                                </div>
                                <div className="flex-5 xl:flex-4 flex flex-col justify-center">
                                    <p className="text-xl font-bold text-primary">
                                        Malha de Veículos
                                    </p>
                                    <p>
                                        Mais de 900 veículos garantem a
                                        qualidade e rapidez da nossa operação.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[75rem] lg:h-[50rem] xl:h-[30rem] flex flex-row justify-center">
                        <img
                            src="https://logplace.com.br/wp-content/uploads/2021/10/shape-bg.jpg"
                            className="absolute h-[75rem] lg:max-h-[50rem] xl:max-h-[30rem] w-full object-cover"
                        />
                        <div className="h-[70rem] lg:h-[40rem] xl:h-[30rem]  flex flex-row xl:space-x-10 lg:px-48 py-10 justify-center">
                            <img
                                src="https://logplace.com.br/wp-content/uploads/2021/10/map.png"
                                className="absolute z-10 w-2/3 md:w-2/2 lg:w-2/3 h-full md:max-h-40 lg:max-h-96 object-contain"
                            />
                            <div className="z-20 flex flex-col justify-center items-center m-0 p-4 lg:p-0 space-y-5 lg:space-y-0">
                                <div className="flex flex-col space-y-4">
                                    <p className="text-red-500 font-medium">
                                        ____
                                    </p>
                                    <p className="text-4xl font-medium text-primary">
                                        Atuação Nacional
                                    </p>
                                    <p>
                                        Com mais de 20 unidades espalhadas pelo
                                        Brasil, garantimos a entrega do seu
                                        produto em qualquer lugar do país.
                                    </p>
                                </div>
                                <div className=" w-full h-full flex flex-col grid-cols-2 gap-2  lg:flex-row space-y-2 justify-center items-center lg:items-end lg:p-4 lg:justify-around lg:space-x-5">
                                    <div className="h-56 w-56 bg-white flex flex-col rounded-lg hover:bg-blue-950 transition duration-300 ease-in-out hover:text-white group">
                                        <div className="flex-1 flex justify-center items-center">
                                            <div className="rounded-full bg-blue-700 w-20 h-20 group-hover:bg-orange-600 transition duration-300 ease-in-out justify-center items-center flex">
                                                <FaCheck
                                                    size={36}
                                                    color="#fff"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-1 flex justify-center items-center flex-col">
                                            <p className="text-emerald-700 text-2xl font-bold group-hover:text-emerald-400 transition duration-300 ease-in-out">
                                                1Mi
                                            </p>
                                            <p className=" ">
                                                Entregas Realizadas
                                            </p>
                                        </div>
                                    </div>
                                    <div className="h-56 w-56 bg-white flex flex-col rounded-lg hover:bg-blue-950 transition duration-300 ease-in-out hover:text-white group">
                                        <div className="flex-1 flex justify-center items-center">
                                            <div className="rounded-full bg-blue-700 w-20 h-20 group-hover:bg-orange-600 transition duration-300 ease-in-out justify-center items-center flex">
                                                <FaRegHandshake
                                                    size={40}
                                                    color="#fff"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-1 flex justify-center items-center flex-col">
                                            <p className="text-emerald-700 text-2xl font-bold group-hover:text-emerald-400 transition duration-300 ease-in-out">
                                                12500
                                            </p>
                                            <p className=" ">
                                                Clientes Satisfeitos
                                            </p>
                                        </div>
                                    </div>
                                    <div className="h-56 w-56 bg-white flex flex-col rounded-lg hover:bg-blue-950 transition duration-300 ease-in-out hover:text-white group">
                                        <div className="flex-1 flex justify-center items-center">
                                            <div className="rounded-full bg-blue-700 w-20 h-20 group-hover:bg-orange-600 transition duration-300 ease-in-out justify-center items-center flex">
                                                <GrAchievement
                                                    size={36}
                                                    color="#fff"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-1 flex justify-center items-center flex-col">
                                            <p className="text-emerald-700 text-2xl font-bold group-hover:text-emerald-400 transition duration-300 ease-in-out">
                                                30+
                                            </p>
                                            <p className=" ">
                                                Prêmios Vencidos
                                            </p>
                                        </div>
                                    </div>
                                    <div className="h-56 w-56 bg-white flex flex-col rounded-lg hover:bg-blue-950 transition duration-300 ease-in-out hover:text-white group">
                                        <div className="flex-1 flex justify-center items-center">
                                            <div className="rounded-full bg-blue-700 w-20 h-20 group-hover:bg-orange-600 transition duration-300 ease-in-out justify-center items-center flex">
                                                <GrUserAdd
                                                    size={36}
                                                    color="#fff"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-1 flex justify-center items-center flex-col">
                                            <p className="text-emerald-700 text-2xl font-bold group-hover:text-emerald-400 transition duration-300 ease-in-out">
                                                450+
                                            </p>
                                            <p className=" ">Colaboradores</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
